import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const Iniziale = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "img3.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 1024, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return <ImgWrapper fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default Iniziale;

const ImgWrapper = styled(Img)`
  @media (max-width: 768px) {
    width: 100vw;
    margin-left: calc(-1 * ((100vw - 100%) / 2));
    margin-right: calc(-1 * ((100vw - 100%) / 2));
  }
`;
