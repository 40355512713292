import { useStaticQuery, graphql } from "gatsby";

export const useSalute = () => {
  const salute = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "imagehome/img3.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 300, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  return salute;
};
