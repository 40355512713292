import React from "react";
import styled, { keyframes } from "styled-components";
import Iniziale from "./HomeImage";
import "./Home.css";
import Contacts from "./contacts";
import Card from "./Card";
import { Link } from "gatsby";
import { useGravidanza } from "./cards/gravidanza";
import { useNascita } from "./cards/nascita";
import { useSalute } from "./cards/salute";
import { pulse } from "react-animations";

const pulseAnimation = keyframes`${pulse}`;

const Home = () => {
  const title = ["Gravidanza", "Dopo la nascita", "Salute e Sessualità"];
  const subtitle = [
    "Scopri i servizi dedicati alla garvidanza",
    "Scopri i servizi dedicati ai neogenitori",
    "Scopri i servizi dedicati al femminile ",
  ];
  const gravidanza = useGravidanza();
  const nascita = useNascita();
  const salute = useSalute();
  return (
    <Container>
      <FlexWrapper>
        <Grid className="grid">
          <Text>
            <div>
              <Subtitle>Ostetrica</Subtitle>
              <TitleWrapper>
                <Title>Giada Meo</Title>
              </TitleWrapper>
              <Paragraph>
                "Ho scelto di essere un’ostetrica libera professionista per
                stare accanto alle donne e alle Famiglie in tutte le fasi della
                vita, non solo durante la Nascita."
              </Paragraph>
            </div>
          </Text>
          <Iniziale />
        </Grid>
      </FlexWrapper>
      <Div>
        <TextBannersmall>
          Ho conseguito la Laurea in Ostetricia nell’Aprile del 2017 con
          votazione di 110/110 e Lode presso l’Università degli studi di Roma La
          Sapienza, con tirocinio formativo presso la sede di Viterbo. La mia
          tesi era così intitolata: “Correlazione tra indice di Apgar e PH da
          cordone ombelicale nei nati a termine”. Subito dopo la laurea ho
          intrapreso la libera professione, e nel Gennaio del 2019 ho realizzato
          un mio grande sogno, ho avuto la possibilità di aprire il mio studio
          ostetrico proprio nella mia città, Civitavecchia. Lavoro anche a
          domicilio sul territorio della provincia di Roma e Viterbo, e presso
          un centro sportivo dove svolgo corsi di Acquaticitá prenatale e
          neonatale. Mi occupo della salute della Donna dall’adolescenza fino
          alla Menopausa, della salute della Coppia e del bambino da 0 a 3 anni.
          Accompagno le Donne durante la loro Gravidanza, le seguo anche dopo il
          parto, nel puerperio e durante l’allattamento.
        </TextBannersmall>
      </Div>
      <Spacer />
      <FlexWrapper>
        <DivWrapper>
          <View>
            <Link to="/servizi#gravidanza">
              <Card
                className="carte"
                images={gravidanza}
                title={title[0]}
                sub={subtitle[0]}
              />
            </Link>
          </View>
          <View>
            <Link to="/servizi#nascita">
              <Card
                className="carte"
                images={nascita}
                title={title[1]}
                sub={subtitle[1]}
              />
            </Link>
          </View>
          <View>
            <Link to="/servizi#salute">
              <Card
                className="carte"
                images={salute}
                title={title[2]}
                sub={subtitle[2]}
              />
            </Link>
          </View>
        </DivWrapper>
      </FlexWrapper>
      <Spacer />
      <Divider />
      <FlexWrapper>
        <Contacts />
      </FlexWrapper>
    </Container>
  );
};

export default Home;

const Divider = styled.hr`
  border: none;
  height: 4px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 32px;
`;

const Container = styled.div`
  background-color: rgb(249, 204, 189);
`;

const Spacer = styled.div`
  height: 2rem;
  background-color: #fff;
`;

const Paragraph = styled.p`
  color: #03363d !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: calc(1em + 8px) !important;
  font-style: italic;
  @media (max-width: 768px) {
    font-size: 1rem !important;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(249, 204, 189) !important;
  width: 100vw;
  margin-left: calc(-1 * ((100vw - 80%) / 2));
  margin-right: calc(-1 * ((100vw - 80%) / 2));
  padding: 0 16vh;
  @media (max-width: 1024px) {
    padding: 0 8vw;
    background-color: rgb(249, 204, 189) !important;
  }
`;

const TitleWrapper = styled.div`
  animation: 0.66s ${pulseAnimation};
`;

const Title = styled.h1`
  color: #03363d !important;
  font-size: 3rem !important;
  font-weight: 700 !important;
`;

const Subtitle = styled.h2`
  color: #03363d !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.6rem !important;
`;

const Text = styled.div`
  margin: 4vh 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff !important;
`;

const TextBannersmall = styled.p`
  text-align: right;
  margin: 1rem 4rem;
  padding: 1rem 1rem 0 0;
  color: #03363d !important;
  border-right: 4px solid rgb(249, 204, 189);
  @media (max-width: 768px) {
    text-align: center;
    margin: 0 1rem;
    border-right: 0;
  }
`;

const DivWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const View = styled.div`
  margin: 1rem;
  background-color: #ffffff;
  width: 290px;
  box-shadow: 2px 10px 15px 1px rgba(231, 217, 217, 1);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  &:hover {
    -webkit-transform: scale(1.04, 1.04);
    transform: scale(1.04, 1.04);
    box-shadow: 4px 8px 12px 0px rgba(231, 217, 217, 1);
  }
`;
