import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const Card = ({ images, title, sub }) => {
  if (images.images) {
    return (
      <DivWrapper>
        {images.images.edges.map(({ node }, i) => (
          <View>
            <ImgWrapper key={i} fluid={node.childImageSharp.fluid} />
            <Title>{title[i]}</Title>
            <Subtitle>{sub[i]}</Subtitle>
          </View>
        ))}
      </DivWrapper>
    );
  } else {
    return (
      <>
        <ImgWrapper fluid={images.image.childImageSharp.fluid} />
        <Title>{title}</Title>
        <Subtitle>{sub}</Subtitle>
      </>
    );
  }
};

export default Card;

const DivWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  white-space: pre-wrap;
`;

const View = styled.div`
  margin: 0.8rem;
  background-color: #ffffff;
  width: 300px;
  box-shadow: 2px 10px 15px 1px rgba(231, 217, 217, 1);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  &:hover {
    -webkit-transform: scale(1.04, 1.04);
    transform: scale(1.04, 1.04);
    box-shadow: 4px 8px 12px 0px rgba(231, 217, 217, 1);
  }
`;

const ImgWrapper = styled(Img)`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const Title = styled.h2`
  padding: 0.8rem;
  padding-bottom: 0.2rem !important;
`;

const Subtitle = styled.p`
  padding-left: 0.8rem !important;
  padding: 0.4rem;
`;
